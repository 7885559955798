<template>
  <main>
    <div class="form">
      <div class="done">
      <h2>Opération terminée, merci de votre participation. </h2>
      <p><br /><br /></p>
      </div>

    </div>

  </main>
</template>
<script>
export default {
  name: 'finish',
  methods : {
    gohome() {
      this.$router.push("/");
    }
  }
}
</script>